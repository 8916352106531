import { cloneElement } from 'react'

import { H3 } from 'components/heading/Heading'
import { Link } from 'components/link/Link'

import s from './CarouselCard.module.scss'
import { ArrowLeft } from 'components/icon/Icon'

type Props = {
  image?: React.ReactElement
  title?: string
  link: string
}

export const CarouselCard = ({ image, title, link }: Props) => {
  if (!link) {
    return null
  }
  return (
    <li className={s.carouselCard}>
      <Link to={link} className={s.carouselCard__link}>
        <div className={s.carouselCard__imageWrap}>
          {image &&
            cloneElement(image, {
              className: s.carouselCard__image,
            })}
        </div>
        <H3 className={s.carouselCard__title}>{title}</H3>
      </Link>
    </li>
  )
}

type ShowMoreProps = {
  title?: string
  link: string
  id: string
}

export const ShowMoreCarouselCard = ({ link, title, id }: ShowMoreProps) => {
  return (
    <li key="more_news" className={s.carouselCard} id={id}>
      <Link to={link} className={s.carouselCard__link}>
        <div className={s.carouselCard__imageWrap}>
          <img
            src="/images/more_news.png"
            alt={title}
            className={s.carouselCard__image}
            loading="lazy"
          />

          <div className={s.carouselCard__floating_container}>
            <H3 className={s.carouselCard__floating_container__title}>
              {title}
            </H3>
            <div className={s.carouselCard__floating_container__arrow}>
              <ArrowLeft />
            </div>
          </div>
        </div>
      </Link>
    </li>
  )
}
