import { asText } from '@prismicio/richtext'
import { Carousel } from 'components/carousel/Carousel'
import {
  FrontpageSlicesNews_Carousel,
  Maybe,
  News,
  NewsConnectionEdge,
} from 'prismic-types'
import { PrismicRichText } from 'prismic/types/richtext'
import { ExcludesFalse } from 'utils/excludesFalse'

type Props = {
  title?: PrismicRichText
  description?: PrismicRichText
  news: News[]
  buttonCardText?: string
}

export const NewsCarousel = ({
  title,
  description,
  news,
  buttonCardText,
}: Props): JSX.Element => {
  const formatTitle = title ? asText(title) : ''

  return (
    <Carousel
      title={formatTitle}
      description={description}
      slices={news}
      buttonCardText={buttonCardText}
    />
  )
}

export const prismicSliceToNewsCarousel = (s: FrontpageSlicesNews_Carousel) => {
  let newsItems: Array<News> = []
  try {
    if (s.variation?.primary?.data) {
      const maybeData =
        (JSON.parse(s.variation.primary.data) as Maybe<NewsConnectionEdge>[]) ??
        []
      newsItems = maybeData
        .map((item) => {
          if (!item || !('title' in item.node)) {
            return null
          }
          return item.node
        })
        .filter(Boolean as unknown as ExcludesFalse)
    }
  } catch (e) {
    console.error('Error parsing dynamic contentlist data', e)
  }

  return (
    <NewsCarousel
      title={s.variation?.primary?.title}
      description={s.variation?.primary?.description}
      news={newsItems}
      buttonCardText={s.variation?.primary?.button_card_text ?? undefined}
    />
  )
}
