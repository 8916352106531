import {
  CallToActionSlice,
  prismicSliceToCallToAction,
} from 'prismic/slices/CallToAction/CallToActionSlice'
import { prismicSliceToContentList } from 'prismic/slices/ContentList/ContentListSlice'
import { prismicSliceToCountDown } from 'prismic/slices/CountDown/CountDownSlice'
import { prismicSliceToFocalItems } from 'prismic/slices/FocalItems/FocalItemsSlice'
import { prismicSliceToGreenLight } from 'prismic/slices/GreenLight/GreenLightSlice'
import {
  NumbersSlice,
  prismicSliceToNumbers,
} from 'prismic/slices/Numbers/NumbersSlice'
import { prismicSliceToPrices } from 'prismic/slices/Prices/PricesSlice'
import {
  FrontpageSlices as PrismicFrontpageSlices,
  FrontpageSlicesContent_List,
  FrontpageSlicesCount_Down,
  FrontpageSlicesFocal_Items,
  FrontpageSlicesGreen_Light,
  FrontpageSlicesPrices,
  FrontpageSlicesNews_Carousel,
} from 'prismic-types'

import { Section } from 'components/section/Section'
import { prismicSliceToNewsCarousel } from 'prismic/slices/NewsCarousel/NewsCarousel'

type Props = {
  slices?: Array<PrismicFrontpageSlices>
}

export function FrontpageSlices({ slices = [] }: Props) {
  return (
    <>
      {slices.map((s: PrismicFrontpageSlices, i: number) => {
        const key = `slice-${s.type}-${i}`

        switch (s.type) {
          case 'prices':
            return (
              <Section key={key}>
                {prismicSliceToPrices(s as FrontpageSlicesPrices)}
              </Section>
            )
          case 'green_light':
            return (
              <Section key={key}>
                {prismicSliceToGreenLight(s as FrontpageSlicesGreen_Light)}
              </Section>
            )
          case 'call_to_action':
            return (
              <Section key={key}>
                {prismicSliceToCallToAction(s as CallToActionSlice)}
              </Section>
            )
          case 'numbers':
            return (
              <Section key={key}>
                {prismicSliceToNumbers(s as NumbersSlice)}
              </Section>
            )
          case 'count_down':
            return (
              <Section key={key} container>
                {prismicSliceToCountDown(s as FrontpageSlicesCount_Down)}
              </Section>
            )
          case 'content_list':
            return (
              <Section key={key}>
                {prismicSliceToContentList(s as FrontpageSlicesContent_List)}
              </Section>
            )
          case 'focal_items':
            return (
              <Section key={key} theme="gray">
                {prismicSliceToFocalItems(s as FrontpageSlicesFocal_Items)}
              </Section>
            )
          case 'news_carousel':
            return (
              <Section key={key}>
                {prismicSliceToNewsCarousel(s as FrontpageSlicesNews_Carousel)}
              </Section>
            )

          default:
            console.info(`unknown slice type ${s.type}`, s)
            return <Section key={key}>{`unknown slice type ${s.type}`}</Section>
        }
      })}
    </>
  )
}
