import { RefObject, useCallback, useEffect, useRef, useState } from 'react'

export const useDragging = () => {
  const slider: RefObject<HTMLDivElement> = useRef(null)

  const [isDragging, setDragging] = useState(false)
  const [scrollPosition, setScrollPosition] = useState({
    isScrollStart: true,
    isScrollEnd: false,
  })

  const initialPos = useRef({ left: 0, x: 0 })

  const checkScrollPosition = useCallback(() => {
    if (slider.current) {
      const { scrollLeft, scrollWidth, clientWidth } = slider.current
      const isAtBeginning = scrollLeft === 0
      const isAtEnd = scrollLeft + clientWidth >= scrollWidth

      setScrollPosition({
        isScrollStart: isAtBeginning,
        isScrollEnd: isAtEnd,
      })
    }
  }, [setScrollPosition])

  const mouseUpHandler = useCallback(() => {
    setDragging(false)
    checkScrollPosition()
    document.removeEventListener('mousemove', mouseMoveHandler)
    document.removeEventListener('mouseup', mouseUpHandler) // skoða
  }, [setDragging, checkScrollPosition])

  const mouseMoveHandler = useCallback(
    (e: MouseEvent) => {
      if (slider.current) {
        // How far the mouse has been moved
        const dx = e.clientX - initialPos.current.x

        // Scroll the element
        slider.current.scrollLeft = initialPos.current.left - dx
        checkScrollPosition()
      }
    },
    [checkScrollPosition]
  )

  const mouseDownHandler = useCallback(
    (e: MouseEvent) => {
      if (slider.current) {
        setDragging(true)

        initialPos.current = {
          left: slider.current.scrollLeft,
          // Get the current mouse position
          x: e.clientX,
        }

        document.addEventListener('mousemove', mouseMoveHandler)
        document.addEventListener('mouseup', mouseUpHandler)
      }
    },
    [mouseMoveHandler, mouseUpHandler, setDragging]
  )

  const sliderButton = useCallback(
    (scrollLeft: boolean, cardDocumentId: string) => {
      if (slider.current) {
        // Get the width of the card
        const cardElement = document.getElementById(cardDocumentId)
        const cardWidth = cardElement
          ? cardElement.getBoundingClientRect().width + 40
          : 400 // Default to 500 if card not found

        slider.current.scrollTo({
          top: 0,
          left:
            slider.current.scrollLeft + (scrollLeft ? -cardWidth : cardWidth),
          behavior: 'smooth',
        })
        setTimeout(checkScrollPosition, 500) // Allow time for smooth scrolling to complete
      }
    },
    [checkScrollPosition]
  )

  useEffect(() => {
    if (slider.current) {
      slider.current.addEventListener('mousedown', mouseDownHandler)
      checkScrollPosition() // Initial check for scroll position
    }

    return () => {
      if (slider.current) {
        slider.current.removeEventListener('mousedown', mouseDownHandler)
      }
    }
  }, [mouseDownHandler, checkScrollPosition])

  return {
    slider,
    isDragging,
    scrollPosition,
    sliderButton,
  }
}
