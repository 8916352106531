import { query } from 'api/prismic'
import { GetStaticProps, InferGetStaticPropsType } from 'next'
import { layoutQuery } from 'prismic/fragments/layoutQuery'
import { frontpageQuery } from 'prismic/queries/frontpageQuery'
import { PrismicLink } from 'prismic/types/link'
import { Frontpage as PrismicFrontpage, Layout } from 'prismic-types'

import { PrismicMeta } from 'containers/meta/PrismicMeta'
import { PageContainer } from 'containers/page/PageContainer'
import { FrontpageSlices } from 'containers/slices/FrontpageSlices'

import { localeToPrismicLocale } from 'utils/i18n'
import {
  singleNavigationQuery,
  topNavigationQuery,
} from 'prismic/fragments/navigationQuery'

type PageProps = {
  page: PrismicFrontpage | null
  preview?: boolean
  layout: Layout | null
  onboardingButtonLink?: PrismicLink
}

export default function PageComponent(
  data: InferGetStaticPropsType<typeof getStaticProps>
) {
  const { page } = data
  if (!page) {
    return null //TODO: 404
  }

  return (
    <>
      <PrismicMeta
        data={page}
        layout={data.layout}
        url={process.env.NEXT_PUBLIC_BASE_URL}
      />
      <PageContainer
        title={page.title}
        seo_title={page.title}
        hero={page.hero}
      />
      <FrontpageSlices slices={page.slices ?? []} />
    </>
  )
}

export const getStaticProps: GetStaticProps<PageProps> = async ({
  preview = false,
  previewData = undefined,
  locale,
}) => {
  const lang = localeToPrismicLocale(locale)
  const uid = 'frontpage'

  // GraphQL hack for deep navigation
  const topNavData = await query(topNavigationQuery, { variables: { lang } })
  const topNav =
    topNavData?.allNavigations.edges
      ?.filter((edge) => edge && edge.node)
      .map((edge) => {
        return edge?.node._meta.uid
      }) ?? []

  const megamenuItems = async () => {
    return await Promise.all(
      topNav.map((uid) =>
        query(singleNavigationQuery, { variables: { lang, uid: uid! } })
      )
    )
  }

  const [navData, pageData, layoutData] = await Promise.all([
    megamenuItems(),
    query(frontpageQuery, {
      previewData,
      variables: { lang, uid },
      cacheKey: `frontpage-${uid}-${lang}`,
    }),
    query(layoutQuery, {
      previewData,
      variables: { lang },
      cacheKey: `layout-${lang}`,
    }),
  ])

  const layout: Layout | null = layoutData?.layout ?? null
  const page = pageData?.frontpage ?? null
  const onboardingButtonLink =
    pageData?.frontpage?.onboarding_button_link ?? null

  const navigation = navData.map((navDataItem) => navDataItem?.navigation)

  if (!page) {
    return {
      notFound: true,
      props: {},
    }
  }

  return {
    props: {
      preview,
      layout,
      navigation,
      page,
      onboardingButtonLink,
    },
    revalidate: 60 * 10,
  }
}
