import { Children } from 'react';
import classnames from 'classnames';

import { CardGrid } from 'components/card-grid/CardGrid';

import s from './ListGrid.module.scss';

const c = classnames.bind(s);
type Props = {
  children: React.ReactNode;
};

export const ListGrid = ({ children }: Props) => {
  const childCount = Children.count(children);
  if (!children) {
    return null;
  }

  return (
    <CardGrid
      className={c(s.listGrid, {
        [s.three]: childCount === 3,
        [s.four]: childCount === 4,
      })}
    >
      {children}
    </CardGrid>
  );
};
