import { linkResolver } from 'prismic/linkResolver';
import {
  FrontpageSlicesFocal_Items,
  FrontpageSlicesFocal_ItemsDefaultItems,
} from 'prismic-types';

import { ListCard } from 'components/list-grid/ListCard';
import { RegularOrCroppableImage } from 'components/picture/RegularOrCroppablePicture';
import { asText, RichText } from 'components/rich-text/RichText';

import { ListGrid } from '../../../components/list-grid/ListGrid';

type FocalItemsItem = FrontpageSlicesFocal_ItemsDefaultItems;

type Props = {
  items: Array<FocalItemsItem>;
};

export type FocalItemsSlice = FrontpageSlicesFocal_Items;

export function ListGridSlice({ items }: Props) {
  const filteredItems = items.filter((item) => item.title && item.text);
  // depending on the number of items we want different sizes
  const imageSizes: Record<string, { width: number; height: number } | undefined> = {
    '1': { width: 860, height: 631 },
    '2': { width: 860, height: 631 },
    '3': { width: 563, height: 482 },
    '4': { width: 415, height: 415 }, // not accoring to design, see: https://app.asana.com/0/1160711308714617/1203370353444930
  };

  const imageSize = imageSizes[filteredItems.length.toString()] ?? imageSizes[1];

  return (
    <ListGrid>
      {filteredItems
        .filter((item) => item.title && item.text)
        .map((item, i) => (
          <ListCard
            offset={i === 1 && filteredItems.length === 2}
            key={i}
            size={filteredItems.length}
            image={<RegularOrCroppableImage image={item.image} {...imageSize} />}
            title={asText(item.title)}
            link={item.link ? linkResolver(item.link) : undefined}
          >
            <RichText>{item.text}</RichText>
          </ListCard>
        ))}
    </ListGrid>
  );
}

export function prismicSliceToFocalItems(s: FocalItemsSlice) {
  const items = (s.variation?.items ?? null) as Array<FocalItemsItem>;

  if (!items.length) {
    return null;
  }

  return <ListGridSlice items={items} />;
}
