import { useIntl } from 'react-intl'
import { useRouter } from 'next/router'

import classNames from 'classnames/bind'

import { H2 } from 'components/heading/Heading'

import { linkResolver } from 'prismic/linkResolver'

import { useDragging } from 'hooks/useDragging'
import { sortContentListItems } from 'prismic/slices/ContentList/ContentListSlice'

import { RichText, asText } from 'components/rich-text/RichText'
import { RegularOrCroppableImage } from 'components/picture/RegularOrCroppablePicture'
import {
  CarouselCard,
  ShowMoreCarouselCard,
} from 'components/carousel-card/CarouselCard'
import { Svg } from 'components/svg/Svg'

import { generateImageSizes } from 'utils/cleverCropping'
import { PrismicRichText } from 'prismic/types/richtext'

import { News } from 'prismic-types'

import s from './Carousel.module.scss'

const c = classNames.bind(s)

type Props = {
  title?: string
  description?: PrismicRichText
  slices: News[]
  buttonCardText?: string
}

export const Carousel = ({
  title,
  description,
  slices,
  buttonCardText,
}: Props) => {
  const widthOnCards = 392
  const heightOnCards = 412

  const lastCardId = 'CarouselCard__More_News'

  const { slider, isDragging, sliderButton, scrollPosition } = useDragging()

  const { query } = useRouter()
  const { formatMessage } = useIntl()

  return (
    <div className={s.container}>
      <div className={s.carouselContainer}>
        <div className={s.carouselHeading}>
          <div className={s.carouselHeading__text}>
            <H2>{title}</H2>
            {description && <RichText>{description}</RichText>}
          </div>

          <div>
            <button
              onClick={() => sliderButton(true, lastCardId)}
              className={c({
                [`${s.carouselHeading__button}`]: true,
                [`${s.disabled}`]: scrollPosition.isScrollStart,
              })}
              disabled={scrollPosition.isScrollStart}
              style={{ marginRight: '16px' }}
            >
              <Svg className={s.carouselHeading__icon} id="arrowleft" />
            </button>

            <button
              onClick={() => sliderButton(false, lastCardId)}
              className={c({
                [`${s.carouselHeading__button}`]: true,
                [`${s.disabled}`]: scrollPosition.isScrollEnd,
              })}
              disabled={scrollPosition.isScrollEnd}
            >
              <Svg className={s.carouselHeading__icon} id="arrowright" />
            </button>
          </div>
        </div>
      </div>
      <div
        ref={slider}
        className={c({
          [`${s.carousel}`]: true,
          [`${s.dragging}`]: isDragging,
        })}
      >
        <ul className={s.carousel__inner}>
          {slices.map((item, i) => {
            const { title, image } = sortContentListItems(item)
            const parent = query.parent as string | undefined

            return (
              <CarouselCard
                key={'CarouselCard__' + i}
                title={asText(title)}
                link={parent ? linkResolver(item, parent) : linkResolver(item)}
                image={
                  image ? (
                    <RegularOrCroppableImage
                      image={image}
                      componentSizes={generateImageSizes({
                        ratio: heightOnCards / widthOnCards,
                        wide: heightOnCards,
                      })}
                    />
                  ) : undefined
                }
              />
            )
          })}

          <ShowMoreCarouselCard
            key="CarouselCard__more_news"
            id={lastCardId}
            link={formatMessage({
              id: 'news_link',
              defaultMessage: 'frettir',
            })}
            title={
              buttonCardText
                ? buttonCardText
                : formatMessage({
                    id: 'show_all_news',
                    defaultMessage: 'Sjá allar fréttir',
                  })
            }
          />
        </ul>
      </div>
    </div>
  )
}
